<template>
  <div>
    <div class="app-main flex-column flex-row-fluid " id="kt_app_main">
      <!--begin::Content wrapper-->
      <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Content-->
        <div id="kt_app_content" class="app-content  flex-column-fluid ">
          <!--begin::Content container-->
          <div id="kt_app_content_container" class="app-container  container-xxl ">
            <!--begin::Post card-->
            <div class="card">
              <!--begin::Body-->
              <div class="card-body p-lg-20 pb-lg-0">
                <!--begin::Layout-->
                <div class="d-flex flex-column flex-xl-row">
                  <!--begin::Content-->
                  <div class="flex-lg-row-fluid me-xl-15">
                    <!--begin::Post content-->
                    <div class="mb-17">
                      <!--begin::Wrapper-->
                      <div class="mb-8">

                        <!--begin::Title-->
                        <div href="#" class="text-gray-900 fs-2 fw-bold" style="text-align: center">
                          PRIVACY NOTICE
                        </div>
                        <!--end::Title-->
                      </div>
                      <!--end::Wrapper-->

                      <!--begin::Description-->
                      <div class="fs-5 fw-semibold text-gray-600">

                        <p class="mb-8 text-gray-900">
                          1. About the Privacy Notice
                        </p>
                        <p class="mb-8">
                          This ‘privacy notice’ explains shortly how and why we collect, store, use and share personal data and your rights to control our use of it.
                        </p>
                        <p class="mb-8 text-gray-900">
                          2. Information about us
                        </p>
                        <p class="mb-8">
                          We are Wanted LLC, with seat in the Kingdom of Denmark, the city of Copenhagen,
                          and management address at 42 Nørrebrogade Str., CRV number 25250060, - the website
                          administrator of <a href="https://app.wantedcrew-int.com/">https://app.wantedcrew-int.com/</a> ,
                          will use your personal data only for the purposes described in the next section of this Privacy Policy.
                        </p>
                        <p class="mb-8 text-gray-900">
                          3. What personal data we process?
                        </p>
                        <p class="mb-8">
                          We process data relating to those we employ, or otherwise engage, to work within our Company.
                          Personal data that we may collect, use, store and share (when appropriate) about you includes
                        </p>
                        <ul class="mb-8">
                          <li>
                            Full legal names;
                          </li>
                          <li>
                            E-mail address;
                          </li>
                          <li>
                            Phone number;
                          </li>
                          <li>
                            Country and city of residence;
                          </li>
                          <li>
                            Emergency contact information;
                          </li>
                          <li>
                            Professional experience;
                          </li>
                          <li>
                            Payment information (Social security number, IBAN & SWIFT);
                          </li>
                          <li>
                            Prior professional experience and professional skills;
                          </li>
                          <li>
                            IP address and type of device used to access The Website;
                          </li>
                          <li>
                            Additional website preferences that The User chooses to change in the Settings menu
                            and web-browsing history being gathered via the Wanted platform cookies and automatically
                            processed afterwards (for more information please visit our
                            <router-link to="/cookie-policy">Cookie Policy</router-link>);
                          </li>
                          <li>
                            Profile picture – workers have the option to attach a profile picture to their profile;
                          </li>
                          <li>
                            CV - workers have the option to attach a CV/Resume picture to their profile;
                          </li>
                          <li>
                            Documents and all the personal data listed in the respective document – namely Driver’s
                            license, Forklift license, First aid certificate, Master Joggler
                            Certificate, Drone certificate, as well as other certificates and
                            qualification related documents – workers may upload them (upon their
                            free will and decision to do so) in order to demonstrate their skills to potential
                            contractors. The website does not require the Workers to upload specific documents
                            but simply provides the option for them to do so.;
                          </li>
                          <li>
                            Any other information which The User may choose to share with Wanted or with another User
                            through the live chat on the webpage. Typically, this information will be questions on working projects.
                          </li>
                        </ul>
                        <p class="mb-8 text-gray-900">
                          4. Why do we use your personal data?
                        </p>
                        <p class="mb-8">
                          Wanted needs your personal data and processes it for several reasons:
                          <ul>
                            <li>
                              It enables us to identify you;
                            </li>
                            <li>
                              It enables you to get paid for the work done;
                            </li>
                            <li>
                              It helps us facilitate the safe and secure process of recruitment;
                            </li>
                            <li>
                              It helps both you and our clients (companies) to find each other and work together;
                            </li>
                            <li>
                              It allows us to comply with legal requirements towards our business operations;
                            </li>
                          </ul>
                        </p>
                        <p class="mb-8 text-gray-900">
                          5. What is the legal ground for the processing of personal data?
                        </p>
                        <p class="mb-8">
                          We collect and use personal information about you when the law allows us to.
                          Most commonly, we use it where we need to:
                          <ul>
                            <li>
                              Fulfil a contract we have entered with you and be able to provide you
                              with the services offered at our platform;
                            </li>
                            <li>
                              Comply with a legal obligation (e.g. keep accurate accounting books);
                            </li>
                          </ul>
                        </p>
                        <p class="mb-8">
                          Less commonly, we may also use personal information about you where:
                          <ul>
                            <li>You have given us consent to use it in a certain way;</li>
                            <li>We have legitimate interests in processing the data;</li>
                          </ul>
                        </p>
                        <p class="mb-8">
                          If you fill in a form on our website to contact us or send us/upload a document: we will
                          store the data you enter (usually name, contact details, message) for the
                          purposes of responding to your enquiry and business development.
                        </p>
                        <p class="mb-8">
                          If you receive our emails: we will keep your email address on file for the purpose of
                          sending you updates and news and occasionally proposing jobs
                          to you which you might have interest in.
                        </p>
                        <p class="mb-8 text-gray-900">
                          6. How Long Do We Keep Personal Information?
                        </p>
                        <p class="mb-8">
                          We keep your personal data for as long as required to fulfil the purpose for which
                          it was collected. We store the data as long as it is necessary and relevant to
                          the contractual relationships between you and us. We need to have a record of your work
                          in the event of any complaints or challenges.
                        </p>
                        <p class="mb-8">
                          We also need to keep the information for a certain period of time even after our
                          relationship with you is over in order to: comply with applicable laws, prevent fraud,
                          collect any due fees, resolve potential disputes, assist with any investigation
                          procedures or support litigation. We also need to preserve evidence within the
                          statutes of limitation period.
                        </p>
                        <p class="mb-8 text-gray-900">
                          6. The rights you have
                        </p>
                        <p class="mb-8">
                          Being a data subject, you have certain rights over your personal data and controllers
                          like us are responsible for fulfilling those rights. Your rights include the right of access
                          to personal data, to rectification of personal data, to erasure of personal data / right to
                          be forgotten, to restrict processing of personal data, to data mobility, to object to
                          certain processing of personal data.
                          If you want to submit a complaint, you can do this in your relevant data protection
                          authority as you can find a list of all EU data protection authorities and their respective
                          contact details here:
                          <ul>
                            <li><a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm</a></li>
                          </ul>
                        </p>
                        <p class="mb-8">
                          We recognize that transparency is an ongoing responsibility so Wanted will keep
                          informing you about any significant data privacy issues that might affect you.
                        </p>
                        <p class="mb-8">
                          Wanted kindly informs you that you may reach out to us with regards to any privacy concerns
                          at: <a href="mailto:privacy@wci.dk">privacy@wci.dk</a> . If you have any questions about this privacy statement, how and why
                          we process personal data, or wish to exercise your privacy rights as listed above, please
                          contact us.
                        </p>
                      </div>
                      <!--end::Description-->
                    </div>
                    <!--end::Post content-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Layout-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Post card-->
          </div>
          <!--end::Content container-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Content wrapper-->
    </div>
  </div>
</template>
<style>
table {
  border-collapse: collapse;
}
table, th, td {
  border: 1px solid black;
  padding: 10px;
}
</style>
<script lang="js">
import {defineComponent} from "vue";

export default defineComponent({
  name: "privacy-notices",
});
</script>
